/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { SnackbarProvider } from 'notistack';
import { CSVLink } from 'react-csv';
import download from '../../../../assets/img/download.png';
import useStyle from './useStyle';
import {
  Toaster, CustomTable, CustomSwitch, Loading,
} from '../../../../components';
import COLUMNS from './constants';
import { getPaymentLinksRows, openModalEditPaymentLink } from './utils';
import getMerchantService from '../../../../services/paymentsLink/getMerchantService';
import putMerchantService from '../../../../services/paymentsLink/putMerchantService';
import postDisableMerchantService from '../../../../services/paymentsLink/postDisableMerchantService';
import getCommerceInfoService from '../../../../services/commerceInfo/getCommerceInfoService';
import putCommerceInfoService from '../../../../services/commerceInfo/putCommerceInfoService';
import AlertConfirm from '../../../../components/Crosscutting/AlertConfirm';

const PaymentLinksTable = (props) => {
  const classes = useStyle();
  const {
    paymentLinks,
    setPopUpAction,
    setDefaultValues,
    totalElementsData,
    onNext,
    onSelectPageSize,
    searchQuery,
    loading,
    onRowRefresh,
  } = props;
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [activeCode, setActiveCode] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const providerRef = React.useRef();
  const [rowSelected, setRowSelected] = React.useState([]);
  const [paymentLinksRows, setPaymentLinksRows] = React.useState([]);
  const [paymentLinksCSVRows, setLinksCSVRows] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getSuccessNewData = (paymentLink) => {
    onRowRefresh({
      active_payment_links: !paymentLink.active_payment_links,
      commercial_name: paymentLink.commercial_name,
      creation_date_on_payment_links: paymentLink.creation_date_on_payment_links,
      document_number: paymentLink.document_number,
      merchant_id: paymentLink.merchant_id,
      merchant_id_cardnet: paymentLink.merchant_id_cardnet,
    });
    providerRef.current.enqueueSnackbar(
      `Se ha ${
        activeCode ? 'activado' : 'desactivado'
      } el link de pago exitosamente`,
      {
        variant: 'success',
        anchorOrigin: {
          vertical: 'botom',
          horizontal: 'center',
        },
      },
    );
  };

  const updatePaymentLink = async (paymentLinkData) => {
    try {
      setIsLoading(true);

      const dataResponse = await getCommerceInfoService(paymentLinkData.document_number);

      if (dataResponse.status !== 200) {
        throw new Error('Ha ocurrido un error al obtener la información del comercio.');
      }

      const { data: merchantDataResponse } = await getMerchantService(paymentLinkData.merchant_id);

      const MerchantPaymentLinkBody = {
        MerchantId: dataResponse.data.merchant_id,
        CardnetMerchantId: paymentLinkData.merchant_id_cardnet || '',
        CardnetTerminalId: merchantDataResponse.CardnetTerminalId,
        Name: paymentLinkData.commercial_name,
        CurrencyCode: merchantDataResponse.CurrencyCode,
        ExpirationDays: merchantDataResponse.ExpirationDays,
        AllowHoldPost: merchantDataResponse.AllowHoldPost,
        PostExceedPercent: merchantDataResponse.PostExceedPercent,
        EnabledForPaymentLinks: !paymentLinkData.active_payment_links,
        EnabledForStaticLinks: !paymentLinkData.active_payment_links,
        EnabledForTransactions: !paymentLinkData.active_payment_links,
        AllowCustomerMessages: merchantDataResponse.AllowCustomerMessages,
        HasProfileImage: merchantDataResponse.HasProfileImage,
      };

      const commerceInfoPaymentLinkBody = {
        commerce_id: dataResponse.data.commerce_id,
        merchant_id_cardnet: paymentLinkData.merchant_id_cardnet || '',
        active_payment_links: !paymentLinkData.active_payment_links,
      };

      if (!activeCode) {
        await postDisableMerchantService(dataResponse.data.merchant_id);
      }

      const responseMerchant = await putMerchantService(MerchantPaymentLinkBody);

      if (responseMerchant.status !== 200) {
        throw new Error('Ha ocurrido un error al actualizar la información del comerciante.');
      }

      const responseCommerceInfo = await putCommerceInfoService(commerceInfoPaymentLinkBody);

      if (responseCommerceInfo.status !== 200) {
        throw new Error('Ha ocurrido un error al actualizar la información del comercio.');
      }

      setIsLoading(false);
      getSuccessNewData(paymentLinkData);
      return true;
    } catch (error) {
      setIsLoading(false);
      setAlert({ status: true, type: 'error', message: error.message || 'Ha ocurrido un error, intente nuevamente.' });
      return false;
    }
  };

  const handleChangeState = (event, rowData) => {
    setRowSelected(rowData);
    setOpenAlertConfirm(true);
    setActiveCode(event.target.checked);
  };

  const handleConfirmChecked = () => {
    updatePaymentLink(rowSelected);
    setOpenAlertConfirm(false);
  };

  function createActions(active, paymentLink) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Tooltip
          title="Cambiar estado"
          aria-label="changeState"
          placement="top"
        >
          <Box alignSelf="center">
            <CustomSwitch
              checked={active}
              onChange={paymentLink.merchant_id_cardnet
                ? (e) => handleChangeState(e, paymentLink)
                : null}
              value={paymentLink}
              name="active"
            />
          </Box>
        </Tooltip>
        <IconButton
          edge="end"
          aria-label="Editar"
          size="small"
          onClick={() => openModalEditPaymentLink(
            paymentLink,
            setDefaultValues, setPopUpAction, getCommerceInfoService, getMerchantService,
          )}
          classes={{ disabled: classes.disabled }}
          disabled={!active}
        >
          <Tooltip title="Editar" aria-label="edit" placement="top">
            <EditIcon className={active ? classes.editIcon : classes.editIconReadOnly} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  useEffect(() => {
    setPaymentLinksRows([]);
    setLinksCSVRows([]);
    getPaymentLinksRows(
      paymentLinks, createActions, setPaymentLinksRows, setLinksCSVRows,
    );
  }, [paymentLinks]);

  return (
    <SnackbarProvider ref={providerRef}>
      { isLoading && <Loading /> }
      <CustomTable
        data={paymentLinksRows}
        columns={COLUMNS}
        totalElementsData={totalElementsData}
        onNext={onNext}
        onSelectPageSize={onSelectPageSize}
        loading={loading}
        searchQuery={searchQuery}
      />

      <AlertConfirm
        isOpen={openAlertConfirm}
        tituloModal="Confirmar"
        mensajeModal={`¿Esta seguro que quiere ${
          activeCode ? 'activar' : 'desactivar'
        } este link de pago?`}
        handleAccept={() => handleConfirmChecked()}
        onCancel
        onClose={() => setOpenAlertConfirm(false)}
      />

      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
      <CSVLink
        data={paymentLinksCSVRows}
        filename="link-pago.csv"
        target="_blank"
      >
        <img src={download} alt="downloadCSV" />
      </CSVLink>

    </SnackbarProvider>
  );
};

export default PaymentLinksTable;
